import React from 'react'
import styled from 'styled-components'
const VersionColorWrapper = styled.div`
    margin-top: 1.6rem;
    .version-a {
        .Polaris-Card {
            background: #F2F7FE;
        }
    }
    .version-b {
        .Polaris-Card {
            background: #F1F8F5;
        }
    }
`
function VersionColor(props) {
    return (
        <VersionColorWrapper>
            {props.children}
        </VersionColorWrapper>
            
    )
}
export default VersionColor;